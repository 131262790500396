<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import CustomerDetail from './CustomerDetail'
/**
 * Elements component
 */
export default {
  page: {
    title: "Customer Detail",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, CustomerDetail },
  data() {
    return {
      title: "Update Customer",
      items: [
        {
          text: "Customer",
          href: "/customer/list",
        },
        {
          text: "Update",
          active: true,
        },
      ]
    };
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Customer-detail :is-edit="true" />
  </Layout>
</template>
